body {
  font-family: "Overpass", sans-serif;
}

.freights-menu-mainSection {}

.status2 {
  right: 0px;
  position: absolute;

  box-shadow: 0px 0px 0px 1px #ffffff;
  background-color: #ffffff;
  border-radius: 14px 0px 0px 12px;


  transition: 0.3s ease-in-out;

  .ontitle {
    text-align: left;

    font: normal normal normal 16px/25px Overpass;
    letter-spacing: 0.38px;
    color: #33778e;
    opacity: 1;
  }
}

.toggle-parent {


  position: absolute;
  justify-content: center;
  right: 0px;
  z-index: 99;
  justify-content: center;
  display: flex;

  text-align: center;
  justify-content: center;

  .toggle_Click {
    position: absolute;
    z-index: 99;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    cursor: pointer;

    justify-content: center;

    width: 30px;
    left: -40px;

    text-align: center;
    justify-content: center;
    height: 100px;
    padding-right: 40px;
    background-color: #ffffff;
    cursor: pointer;

    .toggle_button {
      width: 15px;
      margin-top: 40px;
      margin-left: 15px;
    }

    .toggle_button:active {
      transform: rotateZ(-180deg);
    }
  }
}

.ChangeDrive_head {
  color: var(--unnamed-color-33778e);
  text-align: left;
  font: normal normal 600 16px/25px Overpass;
  letter-spacing: 0.48px;
  color: #33778e;
  opacity: 1;
}

.ChangeDriver {
  position: fixed;
  top: 60px;
  right: 0px;
  height: 100vh;
  // overflow-y: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #edf4fc;
  z-index: 1101;
}

#all {
  color: #4d9de7;
}

.statusmenu {
  color: #2c3244;
}

.card-gods {
  
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #1F1104;
  opacity: 1;
  margin-bottom: 0;
 
}

.middle_bar {
  background-color: #F08A290D;
 padding: 15px 20px;
}

.statusheadding {

  font-size: 16px;
  font-weight: 600;
letter-spacing: 0.48px;
color: #F08A29;
margin-bottom: 0;
}

.docu {
  width: 80px;
  height: 70px;
}

.statusIMg {
  // top: 42px;
  // padding-left: 5px;
  margin-right: 10px;

  .pack2 {
    // width: 23px;
    // height: 20px;
  }
}

.tick7 {
  margin-bottom: 98px;
  // border-left: 1px solid #EDF4FC;
}

.card-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.38px;
  color: #1F1104;
  margin: 0;
}

.timePM {
  display: block;
  margin-top: 5px;
  text-align: left;
  font: normal normal normal 12px/18px Overpass;
  letter-spacing: 0.36px;
  color: #66739b;
  opacity: 1;
}

.viewdetail {
  display: inline-block;
  text-decoration: none;
  text-align: left;
  pointer: cursor;
  font: normal normal bold 12px/18px Overpass;
  letter-spacing: 0.29px;
  color: #4f9fe6;
  opacity: 1;
}








@media query and (max-width: 446) {
  .status {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}

.searchBox {
  position: relative;
  display: flex;

  align-items: center;
}

.searchText {
  border-radius: 20px;
  border: none;
  outline: none;
  height: 40px;
  transition: all 0.2s linear;
}

.searchText:focus {}

.ic-search {
  position: absolute;
 
  
  right: 10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.search-icon {
  position: relative;
  text-align: center;
  cursor: pointer;
  right: 25px;
  top: 49px;
  width: 25px;
  height: 25px;
}