.LoadDetails-main {
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;

    p {
        font-size: 14px !important;
        font-weight: 600;
    }

    label {
        font-size: 12px !important;
    }

    .view-detail-btn-load {
        a {
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0.34px;
            color: #4FBFA4;
            opacity: 1;
        }
    }

    .name-driver {
        font-size: 16px;
        letter-spacing: 0.48px;
        color: #191A1A;
        opacity: 1;

        .rating {

            span {
                font-size: 14px;
                letter-spacing: 0.28px;
                color: #191A1A;
                opacity: 1;
            }
        }


    }

    .doc-img {
        display: flex;

        .document-img {
            width: 100% !important;
        }

        a {
            img {
                margin-left: 8px;

            }
        }
    }


}

.popup-load-details {
    .card-heading-ref-no {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.26px;
        color: #38847C;
    }

    .modal-content {
        padding: 20px;
        background: #F4FCFC 0% 0% no-repeat padding-box;
        border-radius: 15px;
        opacity: 1;
        max-width: 400px;
    }

    table {
        min-width: auto;

        td {
            padding: 10px;
        }
    }
}