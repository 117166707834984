@import "../css//variables.scss";
@import "../css/mixin.scss";

.login-main-section {
  .left-section {
    height: 100vh;
    background-image: url("../images//Login/login_bg.png");

    .left-content {
      text-align: center;
      max-width: 600px;

      color: #ffffff;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .left-login-paragraph {
      text-align: center;
      font: normal normal normal 16px/24px Overpass;
      letter-spacing: 0.38px;
      color: #FFFFFF !important;
      opacity: 0.75;
      padding: 0 20px;
    }

    .heading {
      font: normal normal bold 30px/46px Overpass;
      letter-spacing: 0.72px;
      color: #FFFFFF;
      opacity: 1;

    }
  }

  .right-section {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    .right-content {
      flex-basis: 70%;
      max-width: 600px;
    }

    h3 {
      text-align: left;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0.72px;
      color: #2C3244;
      opacity: 1;
    }

    .form-control {
      border: 1px solid #D3DBDB !important;
      border-radius: 8px !important;
    }

    .col-form-label {
      text-align: left;
      font-size: 14px/22px;
      letter-spacing: 0.28px;
      color: #898E8E !important;
      opacity: 1;
    }

    .login-btn {
      margin-top: 40px;
      a {
        text-decoration: none;
        color: #ffffff;
        padding: 7px 0;
        display: inline-block;
        text-align: center;
        font-size: 16px;
        background: #F08A29 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font-weight: bold;
        letter-spacing: 0.26px;
       text-transform: uppercase;
      }
    }

    .forgot {
      text-align: right;

      a {
        text-decoration: none;
        color: #66739b;
      }
    }

    p {
      color: #6C6864 !important;
      ;
      font-size: 16px;
      text-align: left;
      font-weight: 600;

      span {
        color: #834C18;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 1010px) {
  .right-section {
    padding: 20px !important;

    .login-btn {
      display: grid;
    }
  }
}