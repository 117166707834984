.vehicle-requests-main-modal {
    .modal-content {
        padding: 20px !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #2614070D;
        border-radius: 15px;
        opacity: 1;
        width: 400px !important;
        border: none;
        width: auto;
    }
    .modal-dialog-centered{
        justify-content: center;
    }
}