@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-dashboard {
  @include main-body;
  margin-left: 90px;
  transition: all 0.5 ease-in-out;
}

.banner-dashboard {
  background-image: url("../images/Dashboard/banner_bg.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;
  border-radius: 20px;

  .banner-right {
    position: relative;
    top: -60px;
    // right: 20px;

    .banner-img {
      height: 100%;
      width: 100%;
    }
  }

  .banner-left {
    width: 50%;
    padding: 50px;
    color: #2c3244;
    text-align: justify;

    span {
      letter-spacing: 0.34px;
      color: #6c6864;
      font-size: 14px;
    }

    .heading-banner {
      font-size: 36px;
      letter-spacing: 0.72px;
      color: #1f1104;
    }

    .paragarph-banner a{
      font-size: 16px;
      letter-spacing: 0.38px;
      color: #F08A29;
    }

    .get-start-btn {
      text-decoration: none;
      background-color: #f08a29;
      display: inline-block;
      padding: 10px 30px;
      border-radius: 22px;
      font: normal normal normal 16px/25px Overpass;
      letter-spacing: 0.38px;
      color: #ffffff;
      opacity: 1;
    }
  }
}

.dashboard-card .left-card-section label {
  font-size: 12px !important;
}

.dashboard-card {
  .left-card-section {
    border: none;
    text-align: center;

    background: #fdfeff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000007;
    border-radius: 15px;
    opacity: 1;

    .left-count {
      color: $data-font-color;
      font-weight: bold;
    }

    .top-left {
      color: #3e5656;
      font-weight: bold;
    }

    .card-body {
      hr {
        margin: 10px 10px;
        height: 1px;
        color: #edf4fc;
      }
    }
  }

  .right-card-section {
    //   card 1
    .purple-body {
      .purple {
        color: #d346b3;
        font-weight: bolder;
        font-size: 36px;
        padding-top: 20px;
      }

      .card-style {
        border: none;
        background: #fdfeff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000007;
        border-radius: 15px;
        opacity: 1;
      }

      hr {
        margin: 0 20px;
        height: 1px;
        color: #edf4fc;
      }

      a {
        text-decoration: none;
        color: #d346b3;
        font-weight: bold;
        font-size: 12px;
      }

      .active-count {
        font-weight: bold;
      }

      .active {
        color: #66739b;
      }
    }

    // card 2
    .orange-body {
      .orange {
        color: #ff6819;
        font-weight: bolder;
        font-size: 36px;
        padding-top: 20px;
      }

      .card-style {
        border: none;
        background: #fdfeff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000007;
        border-radius: 15px;
        opacity: 1;
      }

      hr {
        margin: 0 20px;
        height: 1px;
        color: #edf4fc;
      }

      a {
        text-decoration: none;
        color: #ff6819;
        font-size: 12px;
        font-weight: bold;
      }

      .active-count {
        font-weight: bold;
        font-size: 14px;
        margin-left: 10px;
      }

      .active {
        color: #66739b;
      }
    }

    //   card 3
    .green-body {
      .green {
        color: #9e55f2;
        font-weight: bolder;
        font-size: 36px;
        padding-top: 20px;
      }

      .card-style {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 30px #2614070d;
        border-radius: 15px;

        border: none;
      }

      hr {
        margin: 0 20px;
        height: 1px;
        color: #edf4fc;
      }

      a {
        text-decoration: none;
        color: #9e55f2;
        font-size: 12px;
        font-weight: bold;
      }

      .active-count {
        font-weight: bold;
        font-size: 14px;
        margin-left: 10px;
      }

      .active {
        color: #66739b;
      }
    }

    //   card 4
    .blue-body {
      .blue {
        color: #0381ff;
        font-weight: bolder;
        font-size: 36px;
        padding-top: 20px;
      }

      .card-style {
        border: none;
        background: #fdfeff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000007;
        border-radius: 15px;
        opacity: 1;
      }

      hr {
        margin: 0 20px;
        height: 1px;
        color: #edf4fc;
      }

      a {
        text-decoration: none;
        color: #0381ff;
        font-size: 12px;
        font-weight: bold;
      }

      .active-count {
        font-weight: bold;
        font-size: 14px;
        margin-left: 10px;
      }

      .active {
        color: #66739b;
      }
    }
  }

  .count-name {
    font-size: 12px;
    color: $data-font-color;
    margin-bottom: 0;
  }
}

// Ongoing and Notification Style
.details-card {
  margin-bottom: 20px;

  .card-style {
    border-radius: 15px;
    border: none;
    background-color: #fdfeff;
  }

  .ongoing-heading {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #d54f35;
  }

  .blue_link.view-details {
    background: #f08a291a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 7px 12px;
  }

  .blue_link {
    text-decoration: none;
    color: #f08a29;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.34px;
    opacity: 1;
  }

  .name {
    font-size: 16px;
    color: $data-font-color;
    font-weight: 500;
  }

  .location {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.34px;
    color: #66739b;
    opacity: 1;
    margin-bottom: 7px;
  }

  .left-card-dashboard {
    overflow: auto;

    //     #style-1::-webkit-scrollbar-track
    // {
    // 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    // 	border-radius: 10px;
    // 	background-color: #F5F5F5;
    // }

    // #style-1::-webkit-scrollbar
    // {
    // 	width: 12px;
    // 	background-color: #F5F5F5;
    // }

    // #style-1::-webkit-scrollbar-thumb
    // {
    // 	border-radius: 10px;
    // 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    // 	background-color: #555;
    // }

    hr {
      margin: 20px 0px;
      height: 1px;
      color: #f6f2ef;
    }
  }

  // .scroll-style::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   border-radius: 10px;
  //   background-color: #F5F5F5;
  // }

  // .scroll-style::-webkit-scrollbar
  // {
  //   width: 3px;
  //   background-color: #F5F5F5;
  // }

  // .scroll-style::-webkit-scrollbar-thumb
  // {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  //   background-color: #4F9FE6;
  // }

  .right-card-dashboard {
    height: 500px;
    overflow: auto;

    hr {
      margin: 20px 0px;
      height: 1px;
      color: #edf4fc;
    }

    .date-time {
      text-align: right;
      font: normal normal normal 14px/20px Overpass;
      letter-spacing: 0.34px;
      color: #66739b;
      opacity: 1;
    }

    .notification-paragraph {
      text-align: left;
      font: normal normal normal 14px/20px Overpass;
      letter-spacing: 0.34px;
      color: #66739b;
      opacity: 1;
    }

    .notification-heading {
      text-align: left;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.38px;
      color: #2c3244;
      opacity: 1;
    }
  }
}

.popup-heading {
  text-align: center;
  font: normal normal normal 18px Overpass;
  letter-spacing: 0.29px;
  color: #191a1a;
  opacity: 1;
}

.popup-btn-link {
  text-decoration: none;
  background: #4f9fe626 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  margin-top: 10px;

  font: normal normal 600 16px/19px Barlow;
  letter-spacing: 0.38px;
  color: #4f9fe6;
  display: inline-block;
  width: 180px;
}
.view-all-card-1 a {
  text-decoration: none;
  color: #d346b3;
  font-weight: bold;
  font-size: 12px;
}
.view-all-card-2 a {
  text-decoration: none;
  color: #ff6819;
  font-weight: bold;
  font-size: 12px;
}
.view-all-card-3 a {
  text-decoration: none;
  color: #9e55f2;
  font-weight: bold;
  font-size: 12px;
}
.view-all-card-4 a {
  text-decoration: none;
  color: #0381ff;
  font-weight: bold;
  font-size: 12px;
}
.span-card-2-and-4 span {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.modal-header {
  padding: 0;
  border: none;
}

.popup-link-div {
}

@media (max-width: 768px) {
  .modal-content {
    width: 95% !important;
  }
}

.rates-main {
  .form-control {
    width: 250px;
  }
  .transporter {
    margin-top: 30px;
  }
  .shipper {
    margin-top: 30px;
  }

  .heading-top {
    display: flex;
    justify-content: space-between;
    .left {
      font: normal normal 600 14px/22px Overpass;
      letter-spacing: 0.34px;
      color: #f08a29;
      text-transform: uppercase;
      opacity: 1;
    }
    .right {
      a {
        text-decoration: none;
        font: normal normal bold 16px/23px Overpass;
        letter-spacing: 0.38px;
        color: #f08a29;
        opacity: 1;
      }
    }
  }
}
