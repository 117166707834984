@import "../css/variables.scss";

body {
  font-family: 'Overpass', sans-serif;
}

.ab-header {
  width: 100%;
  height: 60px;
  background: var(--unnamed-color-33778e) 0% 0% no-repeat padding-box;
  background: #B76B29 0% 0% no-repeat padding-box;
  opacity: 1;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1001;
}

.navbar-logo {
  width: 50px;
  width: 50px;

  // margin: 7px 0px 0px 10px;
  .img-logo {
    // border-right: 1px solid red;
    border-right: 2px solid #AC6420;
    //  margin: 0px 0px 35px 0px;
    margin-right: 10px;
    padding-right: 12px;
    width: 60px;
    height: 40px;
  }

}

.ab-header-container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;


  .nav-item {
    /* Layout Properties */
    left: 1319px;
    // width: 32px;
    // height: 32px;
    // border: 0.699999988079071px solid #ffffff9a;
    border-radius: 18px;
    opacity: 1;
  }

  .nav-item {
    margin-right: 15px;
    margin-top: 6px;

    .nav-link {
      margin-left: -8px;
      margin-top: -8.5px;

    }

  }
}

.dash {
  margin-left: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) / 28px var(--unnamed-font-family-overpass);
  text-align: left;
  font: normal normal normal 18px/28px Overpass;
  letter-spacing: 0.54px;
  color: #ffffff;
  opacity: 1;
}

.cx-alert {
  position: absolute;
  top: 7px;
  right: 88px;
  width: 20px;
  height: 20px;
  background-color: #F08A29;
  color: #ffffff;
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  border-radius: 50px;
  letter-spacing: .5px;
}

// -----------------------------------
// Change Driver Start
// ----------------------------------

.driver {
  overflow: hidden;
  background-color: #FFFFFF;
  --bs-gutter-x: 0rem !important;
}

.btnsave {
  justify-items: center;

}

#save {
  background-color: #4FBFA4;
  color: #FFFFFF;
  border-radius: 5px;
  border: 0px #4FBFA4;
  border-radius: 5px;


}


.user1 {
  border-top: 1px solid #EDF4FC;
  border-bottom: 1px solid #EDF4FC;



  .radio {
    margin-left: 30px;

  }
}

// -------------------------------------------
// Profile
// -------------------------------------------  

.profile {
  background-color: #FFFFFF;
  height: 100vh;
  position: relative;

}

.Account {
  height: 100vh;
  background-color: #FFFFFF;
  overflow: auto;
  width: 300px;
  min-width: auto;
  transition: all 1s ease;


}

.insidepro {
  border-bottom: 1px solid #F6F2EF !important;

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;

  }
}

.Header-profile-name {
  display: block;
  font-size:16px;
letter-spacing: 0.48px;
color: #191A1A;
font-weight: 600;
margin-bottom: 0;
}

#cx-account {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  // z-index:1020;
  display: none;
  transition: all .4s;

  transition-delay: 3s;

}

#cx-account.cx-open {
  display: block;
}

.cx-account-out {
  position: relative;
  width: 100%;
  height: 100%;
}

.Account-content {
  position: relative;
  top: -0px;
  right: -0px;
  background: #ffffff;
  // width:320px;
  height: 100%;
  transition: all .1s ease;
}

.contnt {
  text-align: left;
  font-size:16px;
  letter-spacing: 0.48px;
  color: #2C3244;
  margin-left: 15px !important;
  opacity: 1;
}

.icon_arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

}

.contnt:hover {
  color: #F08A29;
}

// .contnt:active {
//   font: normal normal normal 20px/23px Overpass;

// }






// Notification 

.card-heading {
  color: #D54F35 !important;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.48px;
}

.see {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.48px;
  text-decoration: none;



  color: #F08A29;

  &:hover{
    color:#F08A29 ;
  }
}

.notification-out {}

.cx-Notify {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Notification-content {
  // top:60px;
  background: #ffffff;
  width: auto;
  overflow: auto;
  height: auto;
  max-width: 450px;
  transition: all 1s ease;
}

.Notification-header {
  // display:flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

#deletbtn {
  width: 100px;
  height: 40px;
  position: absolute;
  margin-left: 150px;
  margin-top: 55px;
  background-color: #FFFFFF;

}

.btndelet {
  text-align: right;

  .arrowdrop {
    position: relative;
    margin-left: 135px;
  }

  ul {
    text-align: center;
    border-radius: 10px;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  #deletebtn {

    color: #2C3244
  }

}

.account-list-style{
  li{
    margin-right: 2rem;
    border-bottom: 1px solid #F6F2EF !important;
  }
}

.promenu{
  margin-left: -1.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}






// -------------------------------------
// Media Query Start
// ------------------------------------






@media only screen and (max-width: 991px) {

  .navbar-nav {
    flex-direction: row !important;
    // display: none;


  }

}
