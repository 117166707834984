@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-section-TermsAndConditions{
    @include main-body;
    margin-left: 90px;
}
.T-C{
    margin: 20px;
    p{
    text-align: justify;
    }

    ul{
        line-height: 40px;
        .main_heading{
            font-weight: 600;
            font-size: 18px;
            text-decoration: underline;
            
        }
        .sub_heading{
            font-weight: 600;
            font-size: 16px;
        }
        .terms_item{
            font-size: 14px;
            margin-left: 10px;
        }
        .terms_sub_item{
            font-size: 14px;
            margin-left: 25px;
        }
    }

    

}