@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-reason {
    @include main-body;
    margin-left: 90px;
}

.reportReason-mainBody {}

.reason-body {
    background: #FCFEFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0091B419;
    border-radius: 15px;
    opacity: 1;
    padding: 20px;
    min-width: 240px;
    width: 100%;
    max-width: 400px;
    bottom: 50%;
    padding: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%);


}

.hr {
    margin: 0 !important;
}

.reason-paragraph {
    text-align: left;
    font: normal normal normal 16px/24px Overpass;
    letter-spacing: 0.48px;
    color: #2C3244;
    opacity: 1;
}

.select-reason {
    text-align: left;
    font: normal normal normal 16px/25px Overpass;
    letter-spacing: 0.48px;
    color: #66739B;
    opacity: 1;
}

.button {
    text-align: right;

    .submit-btn {
        text-decoration: none;
        color: #ffff;
        
        padding: 10px 30px;
        border-radius: 5px;

        background: #F08A29 0% 0% no-repeat padding-box;
    }
}