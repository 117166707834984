#vehicle-popup{
    width: 100vw !important;


}

.name-driver-view-details{
    font-size: 16px Overpass;
letter-spacing: 0.48px;
color: #1F1104;
margin-bottom: 0;
}

.profile-pic-veiw{
    img{
    border-radius: 50%;
    height: 60px;
    width: 60px;
}}

.vehicle-popup{
    li{
        margin-top: 6px;
    }
}

.live-goods-details{
    p{
        font-size: 14px;
        font-weight: 600;
    }
    .card-heading{
        font-size:  16px;
        font-weight: 600;
letter-spacing: 0.48px;
color: #1F1104 !important;
opacity: 1;
    }
}