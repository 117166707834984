.OfferVehicleListing-main{
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;
}
.popup-offer-vehicle{
    .modal-fullscreen .modal-content{
        padding: 20px;
    }
    .border-block{
        border: 1px dashed #DDDDDD;
        border-radius: 10px;
        opacity: 1;
    
        padding: 40px;
        text-align: center;
    }
}
