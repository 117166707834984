@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-body-contactUs {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include main-body;

    margin-left: 90px;

    .contact-us {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

}

.contactUs {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #2619070D;
    border-radius: 25px;
    width: auto;
    max-width: 600px;
    padding: 20px;


}

.continue-btn {
    text-decoration: none;
    color: #FFFFFF;
    background-color: #F08A29;
    padding: 7px 30px;
    border-radius: 20px;

    &:hover {
        color: #FFFFFF !important;
    }
}

.left-paragraph {
    text-align: center;
    font-size: 14px;

letter-spacing: 0.84px;
color: #6C6864;
}

.email {
    text-align: center;
}

.left-heading {
    color: #1F1104;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.44px;

}

@media(max-width:700px) {
    .main-body-contactUs {
        height: auto !important;
    }

    .contact-us {
        flex-direction: column;
    }

}

.get-in-touch {
    color: #D54F35;
    font-size: 20px;
}
.email-contact{
    font-size: 14px ;
    font-weight: 600;
letter-spacing: 0.21px;
color: #1F1104;
}