@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-live-map {

  .offcanvas-backdrop.show{
    opacity: 0;
  }
  transition: all 0.5s ease-in-out;
  margin-left: 70px;
}
.freight-details {
  z-index: 999;
  position: absolute;
  bottom: 20px;
  left: 90px;
  transition: all 0.5s ease-in-out;
}

.freight-name {
  text-align: left;
  font: normal normal normal 16px/22px Overpass;
  letter-spacing: 0.42px;
  color: #2c3244;
  opacity: 1;
  margin-bottom: 0;
}

.freight-link {
  background: #ecf5fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-decoration: none;
  padding: 9px 20px;
  margin-left: 7px;
}

.nav-pills{
  .nav-link.active {
    background-color: transparent;
    letter-spacing: 0.48px;
    color: #F08A29 !important;
    position: relative;
    
  }

  .nav-link.active:after {
    content: "";
    position: absolute;
    background-color: #F08A29;
    width: 30px;
    height: 3px;
    bottom: 1px;
    left: 15px;
    transition: all 1s ease;
  }
}

.driver-route {
  text-align: left;
  font: normal normal bold 12px/18px Overpass;
  letter-spacing: 0.36px;
  color: #4FBFA4;
  opacity: 1;
}

/*------------------------------------
            Status Style
------------------------------------*/
.cxl-status {
  position: relative;
  // top: 30px;
  // left: 30px;
  // right: 30px;
  margin: auto;
  // background: #ffffff;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .03);
  // border-radius: 15px;
  padding: 20px;
  // height: 140px;
  display: none;
}

.cxl-status.cx-active {
  display: block;
}

// .cxl-status-title {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

.cxl-load-status {
  margin-top: 15px;
  display: flex;
  position: relative;
}

.cxl-load-status::before {
  // content: '';
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background-color: #4FBFA4;
  z-index: 990;
}

.cxl-load-status {
  li {
    list-style-type: none;

    .cxl-status-circle {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: #ffff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 991;
      display: inline-block;
    }

    .inner-circle {
      width: 10px;
      height: 10px;
      border-radius: 50px;
      background: #4FBFA4;
      box-shadow: 0 0 6px 0 rgb(0 0 0 / 25%);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 991;
      display: inline-block;
      transform: translate(-50%, -50%);
    }
  }
}

.cxl-load-status {
  li.cx-status-ccenter {
    text-align: center;
  }
}

.cxl-load-status {
  li.cx-status-cright {
    text-align: right;
  }
}

.cxl-load-status {
  li {
    position: relative;
    flex-grow: 1;
  }
}

.cxl-load-status {
  li::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    bottom: 0;
    margin: auto;
    background: #ecf2fa;
    width: 100%;
    height: 4px;
    z-index: 991;
  }
}

.cxl-load-status {
  li.cxl-completed::before {
    background: #4FBFA4;
  }
}

.cxl-load-status {
  li {
    p {
      text-align: center;
      font: normal normal normal 12px/18px Overpass;
      letter-spacing: 0.36px;
      color: #66739b;
      opacity: 1;
    }
  }
}

.cxl-status-details {
  position: absolute;
}

.cxl-load-status {
  li.cx-status-cleft {
    .cxl-status-details {
      top: 25px;
      left: 0;
    }
  }
}

.cxl-load-status {
  li.cx-status-ccenter {
    .cxl-status-details {
      top: 25px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      text-align: center;
    }
  }
}

.cxl-load-status {
  li.cx-status-cright {
    .cxl-status-details {
      top: 25px;
      right: 0;
    }
  }
}
.live-img {
  z-index: 1000;

  .location-img {
    position: absolute;
    top: 250px;
    right: 187px;
  }
  .live-vehicle {
    position: absolute;
    bottom: 95px;
    right: 80px;
  }
}

  
  .toggle_Click {
    top: 0;
    right: 0;
    z-index: 0;
    margin-top: 310px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    cursor: pointer;


    
    .toggle_button {
      // -ms-transform: translateY(4px);
      // -webkit-transform: translateY(4px);
      // transform: translateY(4px);/*Move down*/
      // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);/*Reduce Shadow*/
      border-bottom: none;
      width: 30px;
      background-color: #ffffff;
      height: 90px;
      border-style: none;
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    
      transition: all ease 0.2s;
      box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    
 

    }
    .toggle_button:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    }
    .toggle_arrow{
      
    }
    .toggle_arrow:active{
      transform: rotate(180deg);

    }
  }
  

.seekerimg {
  top: 160;
  transform: translateY(50%, -50%);
  width: 60px;
  margin-bottom: 25px;
}
.inside {
  padding: 20px;
}
.dt {
  text-align: left;
  font-size:  14px;
letter-spacing: 0.42px;
color: #1F1104;
margin-bottom: 0 ;
}
