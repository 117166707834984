body {
  font-family: "Overpass", sans-serif;
}
.section-body {
  height: 100vh;
  margin: 20px 20px 20px 70px;
}
.section-main {
  height: 100%;
    margin: 20px 20px 20px 90px;
}

// --------------------load 
.aby-load-status{
  margin-top:15px;
  display:block;
  position: relative;
}
.aby-load-status::before{


  content: "";
  position: absolute;
  top: -2px;
  left: 22px;
  bottom: 0;
  margin: auto;
  background: #edf4fc;
  width: 4px;
  height: 100%;
  z-index: 991;
}
.aby-load-status li .aby-status-circle{
  width:16px;
  height:16px;
  border-radius:50px;
  background:#ffffff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.25);
  position: relative;
  z-index: 991;
  display:inline-block;
  img{
    vertical-align: top !important;
  }
}
.aby-load-status li.cx-status-ccenter{
  text-align: left;
}
.aby-load-status li.cx-status-cright{
  text-align: left;
}
.aby-load-status li{
  position:relative;
  flex-grow:1;
  display: flex;
  padding-bottom: 20px;
}
.aby-load-status li .status-details-image{
  position:relative;
  flex-grow:1;
  display: flex;
}
.aby-load-status li::before{

  content: "";
  position: absolute;
  top: -2px;
  left: 6px;
  bottom: 0;
  margin: auto;
  // background: #ECF2FA;
  width: 4px;
  height: 90%;
  z-index: 991;
}

.aby-load-status li.aby-completed::before{
}

.aby-load-status li p{
  font-size:12px;
  margin-bottom:0;
}
.aby-load-status li span{
  font-size:12px;
}


.aby-load-status li.cx-status-ccenter .aby-status-details{
  top:25px;
  left:0;
  bottom:0;
  right:0;
  margin:0;
  text-align: center;
}
.aby-load-status li.cx-status-cright .aby-status-details{
  // top:25px;
  right:0;
}

@media(max-width:991px){
 
  
  .aby-load-status{
      display:block;
  }
  .aby-load-status li{
      padding-bottom:21px;
  }
  .aby-status{
      width:300px;
  }
  .aby-status-circle{
      margin-right:15px;
  }
  .aby-status-details{
      // position:static;
      // display:inline-block;
  }
  .aby-load-status li:nth-child(1)::before{
      height:100%;
      display: inline;
      top:0;
  }
  .aby-load-status li::before {
      height:90%;
      width:4px;
      top: -40%;
      left: 6px;
      bottom:0;
      background-color: #edf4fc;
      margin:auto;
  }
  .aby-load-status li:nth-last-child(1)::before{
      height:50%;
      top:-110%;
  }
  .aby-load-status::before{
      content:none;
  }
  .aby-load-status li.cx-status-ccenter {
      text-align: left;
  }
  .aby-load-status li.cx-status-ccenter .aby-status-details {
      text-align: left;
  }
  .aby-load-status li.cx-status-cright .aby-status-details{
      text-align: left;
  }
  .aby-load-status li.cx-status-cright {
      text-align: left;
  }
  .aby-load-status li.cx-status-cright .aby-status-details{
      text-align: left;
  }
  
  
}






